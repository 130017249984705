<template>
  <span :class="getClass()" :style="styles"></span>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps(['name', 'hoverName', 'styles', 'classes']);
const { name, hoverName, styles, classes } = toRefs(props);

const getClass = () => {
  let className = '';

  if (name.value) className += name.value;
  if (hoverName.value) className += ' hover:' + hoverName.value;
  if (classes.value) className += ' ' + classes.value;

  return className;
}
</script>