import { defineStore } from "pinia";
import axios from "axios";

export const useAuth = defineStore("auth-store", {
  state: () => {
    return {
      loading: false,
      loginStatus: Boolean(Number(localStorage.getItem("login_status"))),
      apiToken: localStorage.getItem("api_token"),
      user: {},
      store: {},
      // TODO: otros campos
    };
  },

  actions: {
    // TODO: implementar metodos de auth
    // async register(
    //   storeId,
    //   name,
    //   email,
    //   confirmEmail,
    //   password,
    //   confirmPassword
    // ) {
    //   this.loading = true;

    //   try {
    //     const response = await axios.post(
    //       import.meta.env.VITE_API_BASE_URL + "register",
    //       {
    //         store_id: storeId,
    //         name: name,
    //         email: email,
    //         email_confirmation: confirmEmail,
    //         password: password,
    //         password_confirmation: confirmPassword,
    //       }
    //     );

    //     if (response.data.status) {
    //       await this.login(email, password);
    //     }

    //     this.loading = false;

    //     return true;
    //   } catch (error) {
    //     // console.log("Register error:", error)

    //     this.loading = false;

    //     throw error;
    //   }
    // },
    async login(email, password) {
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + "login",
          { email: email, password: password }
        );

        this.apiToken = response.data.token;
        this.loginStatus = true;

        localStorage.setItem("api_token", this.apiToken);
        localStorage.setItem("login_status", this.loginStatus ? 1 : 0);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.apiToken}`;

        this.loading = false;

        return true;
      } catch (error) {
        // console.log("Login error:", error)

        axios.defaults.headers.common["Authorization"] = null;

        this.loading = false;

        throw error;
      }
    },
    async localLogout() {
      this.loading = true;

      this.loginStatus = false;
      this.apiToken = "";
      this.user = {};

      localStorage.setItem("api_token", null);
      localStorage.setItem("login_status", 0);

      axios.defaults.headers.common["Authorization"] = null;

      this.loading = false;

      return true;
    },
    // async activateAccount(username) {
    //   this.loading = true;

    //   try {
    //     const response = await axios.post(
    //       import.meta.env.VITE_API_BASE_URL + "activate_account",
    //       { email: username }
    //     );

    //     this.loading = false;

    //     return response;
    //   } catch (error) {
    //     // console.log("Password Recovery error:", error)

    //     this.loading = false;

    //     throw error;
    //   }
    // },
    // async passwordRecovery(username) {
    //   this.loading = true;

    //   try {
    //     const response = await axios.post(
    //       import.meta.env.VITE_API_BASE_URL + "password_recovery",
    //       { email: username }
    //     );

    //     this.loading = false;

    //     return response;
    //   } catch (error) {
    //     // console.log("Password Recovery error:", error)

    //     this.loading = false;

    //     throw error;
    //   }
    // },
    // async resetPassword(username, newPassword, signature) {
    //   this.loading = true;

    //   try {
    //     const response = await axios.post(
    //       import.meta.env.VITE_API_BASE_URL + "reset_password/" + username,
    //       {
    //         new_password: newPassword,
    //       },
    //       {
    //         params: {
    //           signature: signature,
    //         },
    //       }
    //     );

    //     this.loading = false;

    //     return response;
    //   } catch (error) {
    //     // console.log("Reset Password error:", error)

    //     this.loading = false;

    //     throw error;
    //   }
    // },

    async setStore(store) {
      this.store = store;
    },
  },
});
