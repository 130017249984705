<template>
  <!-- Notiwind notifications -->
  <notification-component />
  <!-- Desktop App Page -->
  <div class="max-w-full hidden lg:flex">
    <!-- Drawer layout -->
    <div class="drawer" :class="{ 'lg:drawer-open': layoutIsSidebarOpen }">
      <input type="checkbox" class="drawer-toggle" />
      <div class="drawer-content p-2">
        <div class="flex flex-col gap-2">
          <div class="navbar bg-secondary rounded-3xl" v-if="layoutIsHeaderVisible">
            <div class="flex-1 px-2">
              <p class="text-xl font-semibold text-white"> Giftcards</p>
            </div>
            <div class="flex-none">
              <ul class="menu menu-horizontal py-0 px-1">
                <!-- <li><a>Link</a></li> -->
                <li>
                  <div class="dropdown dropdown-bottom dropdown-end p-1" :class="{ 'dropdown-open': showDropdown}" @click="toggleDropdown">
                    <label class="btn btn-circle swap swap-rotate" :class="{ 'swap-active': showDropdown}">                      
                      <!-- ellipsis icon -->
                      <span class="swap-off icon-[mdi--ellipsis-vertical]" style="width: 2.5em; height: 2.5em;"></span>
                      <!-- close icon -->
                      <span class="swap-on icon-[mdi--close]" style="width: 2.5em; height: 2.5em;"></span>
                    </label>
                    <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                      <li><button @click="logout" type="button" class="btn">Cerrar sesión</button></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- Page content here -->
          <router-view></router-view>
        </div>
      </div> 
      <div class="drawer-side bg-primary">
        <div class="flex flex-col gap-5" v-if="layoutIsSidebarVisible">
          <!-- Logo CityKids -->
          <div class="w-full p-2"><img class="w-full" src="@/assets/logo.webp" alt="CityKids Logo"></div>
          <ul class="w-full px-0 menu text-white">
            <!-- Sidebar content here -->
            <li v-for="mi in menuItems"
              class="rounded-lg text-lg"
              :class="{ 'cursor-not-allowed bg-neutral opacity-25 text-white': !mi.active }">
              <router-link
                :to="mi.href"
                :class="{
                  'bg-gradient-to-r from-secondary to-primary font-semibold text-white': fullPath.startsWith(mi.href),
                  'pointer-events-none': !mi.active
                }"
              ><icon-component :name="mi.icon" classes="w-6 h-6" />{{ mi.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile App Under Construction Page -->
  <div class="max-w-max flex lg:hidden px-2 py-2"><under-construction-mobile /></div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import IconComponent from "@/components/icons/IconComponent.vue";
import UnderConstructionMobile from "@/components/errors/UnderConstructionMobile.vue";
import { useRoute } from "vue-router";
import { useLayout } from "@/stores/layout";
import { useAuth } from "@/stores/auth";
import { useRouter } from "vue-router";
import NotificationComponent from "@/components/notifications/NotificationComponent.vue";

const route = useRoute();
const layout = useLayout();
const auth = useAuth();
const router = useRouter();

// Computed
const layoutIsSidebarOpen = computed(() => layout.isSidebarOpen);
const layoutIsSidebarVisible = computed(() => layout.isSidebarVisible);
const layoutIsHeaderVisible = computed(() => layout.isHeaderVisible);

// Variables
const { fullPath } = toRefs(route);

const menuItems = ref([
  { icon: 'icon-[mdi--gauge]', title: 'Home', href: '/home', active: true },
  { icon: 'icon-[mdi--user]', title: 'Clientes', href: '/customers', active: true },
  { icon: 'icon-[mdi--category]', title: 'Categorías', href: '/categories', active: true },
  { icon: 'icon-[mdi--coupon]', title: 'Giftcards', href: '/giftcards', active: true },
  { icon: 'icon-[mdi--attach-money]', title: 'Ordenes', href: '/orders', active: true },
  { icon: 'icon-[mdi--package]', title: 'Productos', href: '/products', active: true },
  { icon: 'icon-[mdi--balloon]', title: 'Test', href: '/test', active: false },
]);

const showDropdown = ref(false);

// Methods
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
}

const logout = async () => {
  await auth.localLogout();
  router.push("/login");
}
</script>
