import { createApp } from "vue";
import App from "./App.vue"
import router from "./router";
import store from "./store";
import { useAuth } from "./stores/auth";
import "./index.css";
import "./custom.css";
import axios from "axios";
import mixin from "./helpers.js";
import Notifications from "notiwind";
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "@vueform/multiselect/themes/default.css";
// TODO: ¿i18n?

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.some(
          (e) => e.message && e.message.indexOf("E_UNAUTHORIZED_ACCESS") !== -1
        )) ||
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.some(
          (e) => e.message && e.message.indexOf("E_INVALID_API_TOKEN") !== -1
        )) ||
      (error &&
        error.response &&
        error.response.status == 401 &&
        (!error.response.statusText ||
          error.response.statusText == "Unauthorized"))
    ) {
      // Logout if error response message contains 'E_UNAUTHORIZED_ACCESS' code
      const auth = useAuth();
      await auth.localLogout();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const loginStatus = Boolean(Number(localStorage.getItem("login_status")));
const apiToken = localStorage.getItem("api_token");
if (loginStatus && apiToken) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${apiToken}`;
}

// const navigatorLanguage =
// navigator.languages && navigator.languages.length
//   ? navigator.languages[0]
//   : navigator.userLanguage ||
//     navigator.language ||
//     navigator.browserLanguage ||
//     "es";

// TODO: ¿i18n?

createApp(App)
  .use(router)
  .use(store)
  .use(Notifications)
  .component("VueDatePicker", VueDatePicker)
  .component("Multiselect", Multiselect)
  .mixin(mixin)
  .mount("#app");
